import { EntityModel } from '../../../globalModels/common/entityModel';
import { ChecklistInventoryModel, ChecklistInventoryRestockModel, ChecklistRestockTaskModel } from './itemTypes';

export type ChecklistModel = EntityModel & {
  Name?: string;
  Type: ChecklistType;
  TypeString?: string;
  Sections: ChecklistSection[];
  Description?: string;
  IsDisabled: boolean;
  RequiredItems: ChecklistRequiredItem[];
  AdditionalFees: ChecklistAdditionalFee[];
  RestockTasks: ChecklistRestockTaskModel[];
  InventoryItems: ChecklistInventoryModel[];
  InventoryItemRestock: ChecklistInventoryRestockModel[];
  Images: string[];
};

export type ChecklistQuestion = EntityModel & {
  Label?: string;
  SectionId?: number;
  Type: ChecklistQuestionType;
  CustomStepSlug?: string;
  QuestionSubmissionModel?: ChecklistQuestionSubmission;
};

export type ChecklistRequiredItem = EntityModel & {
  Label: string;
  Response: boolean | undefined;
};

export type ChecklistAdditionalFee = EntityModel & {
  Label: string;
  Response: boolean | undefined;
};

export type ChecklistSection = EntityModel & {
  SectionName?: string;
  QuestionModels: ChecklistQuestion[];
  SectionType: ChecklistSectionType;
};

export type ChecklistQuestionSubmission = EntityModel & {
  BooleanResponse?: boolean;
  TextResponse?: string;
  NumericResponse?: number;
  ChecklistQuestionId: number;
};

export enum ChecklistQuestionType {
  None = 0,
  YesNo = 1,
  YesNoNotApplicable = 2,
  StarRating = 3,
  Checkbox = 4,
  Images = 5,
  TextArea = 6
}

export enum ChecklistType {
  None = 0,
  Cleaning = 1,
  Inspection = 2
}

export enum ChecklistSectionType {
  None = 0,
  Cleaner = 1,
  Helper = 2,
  Inspection = 3
}

export const ChecklistSectionTypeDisplay: Record<ChecklistSectionType, string> = {
  [ChecklistSectionType.None]: 'None',
  [ChecklistSectionType.Cleaner]: 'Cleaner',
  [ChecklistSectionType.Helper]: 'Helper',
  [ChecklistSectionType.Inspection]: 'Inspection'
};
