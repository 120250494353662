import { Box, Card, CardBody, CardHeader, Heading } from '@chakra-ui/react';
import { PropsWithChildren, ReactNode } from 'react';
import { getContentAreaHeight } from '../../util/style';

type CardPageContainerProps = PropsWithChildren & {
  header?: string;
  headerRender?: () => ReactNode;
  disableHeight?: boolean;
};

export const CardPageContainer = (props: CardPageContainerProps) => {
  return (
    <Box>
      <Card>
        <CardHeader>
          {props.header && <Heading fontSize={{ base: 'md', large: 'lg' }}>{props.header}</Heading>}
          {props.headerRender && props.headerRender()}
        </CardHeader>
        <CardBody maxH={props.disableHeight ? 'unset' : getContentAreaHeight()} overflow={'auto'}>
          <Box>{props.children}</Box>
        </CardBody>
      </Card>
    </Box>
  );
};
