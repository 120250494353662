import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const mopspotInput = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '2px',
    border: '1px solid #707070',
    marginBottom: '10px',
    _readOnly: {
      opacity: 0.4
    }
  },
  icon: {
    borderRadius: '0'
  }
});

const readonly = definePartsStyle({
  field: {
    background: 'gray',
    borderRadius: '2px',
    border: '1px solid #707070',
    marginBottom: '10px'
  },
  icon: {
    borderRadius: '0'
  }
});

const smallInput = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '2px',
    border: '1px solid #707070',
    marginBottom: '2px',
    height: '20px'
  },
  icon: {
    borderRadius: '0'
  }
});

const fieldArray = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '2px',
    border: '1px solid #707070',
    w: '100%',
    _readOnly: {
      opacity: 0.4
    }
  },
  icon: {
    borderRadius: '0'
  },
  _readOnly: {
    border: '3px solid purple'
  }
});

export const inputTheme = defineMultiStyleConfig({
  variants: {
    mopspotInput,
    fieldArray,
    smallInput,
    readonly
  },
  defaultProps: {
    variant: 'mopspotInput'
  }
});
