import { useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../../../constants/apiRoutes';
import { getAxios } from '../../../util/axios';
import { CustomerChecklistModel } from '../types';

async function get(id: string) {
  const response = await getAxios().get<CustomerChecklistModel>(`${apiRoutes.customerChecklist.Get(id)}`);
  return response.data;
}

export function useCustomerChecklistModelQuery(id: string) {
  return useQuery({
    queryKey: ['cleaningScheduleEvents', id],
    queryFn: () => get(id),
    enabled: !!id
  });
}
