import { Box, ChakraBaseProvider } from '@chakra-ui/react';
import { RouterProvider } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { useAuth } from '@cksoftware/react-base';
import { useEffect } from 'react';
import { ErrorContainer } from './ErrorContainer';
import { MopspotUser } from './globalModels/user/mopspotUser';
import { HubRoutes } from './routes/HubRoutes';
import { ChakraTheme } from './theme/chakraTheme';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true
    }
  }
});

export const App = () => {
  const { initUser, hasLoaded } = useAuth<MopspotUser>();

  useEffect(() => {
    async function initAuth() {
      await initUser();
    }

    initAuth();
  }, []);

  return (
    <Box maxHeight={'100vh'}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />

        <ChakraBaseProvider theme={ChakraTheme} cssVarsRoot='body' resetCSS={true}>
          <ErrorContainer>{hasLoaded && <RouterProvider router={HubRoutes} />}</ErrorContainer>
        </ChakraBaseProvider>
      </QueryClientProvider>
    </Box>
  );
};
