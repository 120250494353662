import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { NavBar } from '../../features/header/navBar';

export const WideLayout = () => {
  return (
    <Box as='section' width='100vw' height='100vh'>
      <NavBar />
      <Box
        w={['100%']}
        height='calc(100vh - 100px)'
        margin={'auto'}
        paddingLeft={'5px'}
        paddingRight={'5px'}
        paddingTop={'10px'}>
        <Outlet />
      </Box>
    </Box>
  );
};
