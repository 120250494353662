import { ApplicationRoutes } from '../constants/appRoutes';

export const CleaningRoutes = [
  {
    path: ApplicationRoutes.cleaning.startCleaning,
    async lazy() {
      const component = await import('../features/cleaning/dashboard/StartCleaning');
      return { Component: component.StartCleaning };
    }
  },
  {
    path: `/cleaning/cleaning-checklist/:type/:id`,
    async lazy() {
      const component = await import('../features/cleaning/checklist/CleaningChecklist');
      return { Component: component.CleaningChecklist };
    }
  },
  {
    path: ApplicationRoutes.training.list,
    async lazy() {
      const component = await import('../features/training/view/TrainingVideos');
      return { Component: component.TrainingVideos };
    }
  },
  {
    path: ApplicationRoutes.training.view,
    async lazy() {
      const component = await import('../features/training/view/TrainingVideoPlayer');
      return { Component: component.TrainingVideoPlayer };
    }
  }
];
