import { Box, Flex, Image, Link } from '@chakra-ui/react';
import logo from '../../assets/mopspot-logo.png';
import { PropsWithChildren } from 'react';

export const CustomerLayout = (props: PropsWithChildren) => {
  return (
    <Box as='section' width='100vw' height='100vh'>
      <Box
        bg={'white'}
        height={'70px'}
        marginBottom={'5px'}
        borderTopWidth={{ base: '5px', lg: '10px', xl: '15px' }}
        borderTopColor={'secondary.500'}
        borderStyle={'solid'}
        boxSizing='content-box'
        boxShadow={'0px 2px 4px 0px #80808054'}>
        <Flex color={'white'} w={'100%'} h={'100%'} alignItems={'center'} gap={5} justifyContent={'space-between'}>
          <Box paddingLeft={'20px'}>
            <Link href='/'>
              <Image height={['40px']} src={logo} alt='MopSpot' />
            </Link>
          </Box>
        </Flex>
      </Box>
      <Box
        w={['100%']}
        height='calc(100vh - 100px)'
        margin={'auto'}
        paddingLeft={'5px'}
        paddingRight={'5px'}
        paddingTop={'10px'}>
        {props.children}
      </Box>
    </Box>
  );
};
