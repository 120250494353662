import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const mopspotInput = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '2px',
    border: '1px solid #707070'
  },
  icon: {
    borderRadius: '0'
  }
});

const searchForm = definePartsStyle({
  field: {
    background: 'white',
    borderRadius: '2px',
    boxShadow: 'inset 2px 2px 1px #00000029'
  },
  icon: {
    borderRadius: '0'
  }
});

export const selectTheme = defineMultiStyleConfig({
  variants: {
    mopspotInput,
    searchForm
  },
  defaultProps: {
    variant: 'mopspotInput'
  }
});
