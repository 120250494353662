import { ApplicationRoutes } from '../constants/appRoutes';

export const SchedulingRoutes = [
  {
    path: ApplicationRoutes.scheduling.calendar,
    async lazy() {
      const component = await import('../features/scheduling/Calendar/Calendar');
      return { Component: component.Calendar };
    }
  }
];
