import { ProtectedRoute } from '@cksoftware/react-base';
import { createBrowserRouter } from 'react-router-dom';
import { NarrowLayout } from '../components/layout/narrowLayout';
import { WideLayout } from '../components/layout/wideLayout';
import { Home } from '../components/misc/Home';
import { PageNotFound } from '../components/misc/PageNotFound';
import { AppRoles } from '../constants/appRoles';
import { AdminRoutes } from './AdminRoutes';
import { CleaningAdminRoutes } from './CleaningAdminRoutes';
import { CleaningRoutes } from './CleaningRoutes';
import { CrmRoutes } from './CrmRoutes';
import { SchedulingRoutes } from './SchedulingRoutes';
import { UnauthorizedRoutes } from './UnauthorizedRoutes';
import { ApplicationRoutes } from '../constants/appRoutes';
import { CustomerLayout } from '../components/layout/customerLayout';
import { CustomerChecklist } from '../features/customerChecklist/CustomerChecklist';

export const HubRoutes = createBrowserRouter([
  {
    path: '/',
    element: <NarrowLayout />,
    children: [
      {
        path: '/',
        Component: Home
      }
    ]
  },
  {
    path: '/',
    element: <NarrowLayout containerProps={{ maxW: '1200px' }} />,
    children: UnauthorizedRoutes
  },
  {
    path: '/',
    element: (
      <ProtectedRoute allowedRoles={[AppRoles.Admin]}>
        <NarrowLayout />
      </ProtectedRoute>
    ),
    children: AdminRoutes
  },
  {
    path: '/',
    element: (
      <ProtectedRoute allowedRoles={[AppRoles.Admin]}>
        <NarrowLayout />
      </ProtectedRoute>
    ),
    children: CrmRoutes
  },
  {
    path: '/',
    element: (
      <ProtectedRoute allowedRoles={[AppRoles.Manager, AppRoles.Admin]}>
        <NarrowLayout />
      </ProtectedRoute>
    ),
    children: CleaningAdminRoutes
  },
  {
    path: '/',
    element: (
      <ProtectedRoute allowedRoles={[AppRoles.Manager, AppRoles.Admin]}>
        <WideLayout />
      </ProtectedRoute>
    ),
    children: SchedulingRoutes
  },
  {
    path: '/',
    element: (
      <ProtectedRoute allowedRoles={[AppRoles.TeamMember]}>
        <WideLayout />
      </ProtectedRoute>
    ),
    children: CleaningRoutes
  },
  {
    path: `${ApplicationRoutes.customer.checklist}/:cleaning`,
    element: (
      <CustomerLayout>
        <CustomerChecklist />
      </CustomerLayout>
    )
  },
  {
    path: '*',
    element: <NarrowLayout />,
    children: [
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
]);
