import { MenuItem, MenuList } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavItem } from './types/NavItem';

type NavMenuSubItemListProps = {
  subItems: NavItem[];
};

export const NavMenuSubItemList = ({ subItems }: NavMenuSubItemListProps) => {
  const nav = useNavigate();

  const onNavClick = useCallback(
    (navItem: NavItem) => {
      if (navItem.path) {
        nav(navItem.path);
      }
    },
    [nav]
  );

  return (
    <MenuList>
      {subItems.map((value, key) => {
        return (
          <MenuItem
            textColor={'black'}
            key={key}
            onClick={() => {
              onNavClick(value);
            }}>
            {value.text}
          </MenuItem>
        );
      })}
    </MenuList>
  );
};
