(window as any).global ||= window;
import { createRoot } from 'react-dom/client';

import '@fontsource-variable/montserrat';
import { App } from './App';
import { getAxios } from './util/axios';

(window as any).getAxios = getAxios;

// Version 2: Using functions

const container = document.getElementById('root')!;

const root = createRoot(container);
root.render(<App />);
