import { Box } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import { useEffect } from 'react';

export const Home = () => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.hasLoaded) {
      return;
    }
  }, [auth.isAuthenticated, auth.user, auth.hasLoaded]);
  return <Box>Welcome to the MopSpot Hub.</Box>;
};
