import { ApplicationRoutes } from '../constants/appRoutes';

export const AdminRoutes = [
  {
    path: ApplicationRoutes.admin.users,
    async lazy() {
      const component = await import('../features/userAdmin/ManageUsers');
      return { Component: component.ManageUsers };
    }
  }
];
