import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Icon
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useCustomerChecklistModelQuery } from './api/useCustomerChecklistModelQuery';
import { LoadingSpinner, StandardAlert, useGetMultipleFilesQuery } from '@cksoftware/react-base';
import { CustomerChecklistRowDisplay } from './components/CustomerChecklistRowDisplay';
import { CardPageContainer } from '../../components/common/CardPageContainer';
import { formatDateDateOnly } from '../../util/dates';
import { ImageThumbnail } from '../../components/common/ImageThumbnail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { CalendarAlert } from '../../components/CalendarAlert';

export const CustomerChecklist = () => {
  const { cleaning } = useParams();
  const checklistModel = useCustomerChecklistModelQuery(cleaning);
  const files = useGetMultipleFilesQuery(checklistModel.data?.Images ?? []);

  if (checklistModel.isLoading || !checklistModel.data || files.isLoading || !files.data) {
    return <LoadingSpinner />;
  }
  return (
    <Flex maxW={'1400px'} margin={'auto'} flexDir={'column'} gap={'8px'}>
      <CardPageContainer header={'Your Cleaning Details'}>
        <Box>
          <Flex flexDir={'column'} gap={'12px'}>
            <CustomerChecklistRowDisplay Header={'Client'} Text={checklistModel.data.Client} />
            <CustomerChecklistRowDisplay Header={'Address'} Text={checklistModel.data.Address} />
            <CustomerChecklistRowDisplay Header={'Clean Type'} Text={checklistModel.data.CleanType} />
            <CustomerChecklistRowDisplay
              Header={'Cleaned On'}
              Text={formatDateDateOnly(checklistModel.data.CleanedOn)}
            />
            <CustomerChecklistRowDisplay Header={'Cleaner'} Text={checklistModel.data.Cleaner} />
            <StandardAlert status={'info'}>
              <Box>
                Your cleaner left the following note:
                <br />
                <Box>{checklistModel.data.NotesFromCleaner} test123</Box>
              </Box>
            </StandardAlert>
          </Flex>
        </Box>
      </CardPageContainer>
      <CardPageContainer header={'Checklist'}>
        <Box>
          <Accordion allowMultiple={true}>
            {checklistModel.data.ChecklistItems.map((item, key) => {
              const missingQuestions = item.Questions.filter((val) => !val.Complete);
              return (
                <AccordionItem key={key}>
                  <Heading>
                    <AccordionButton>
                      <Flex w={'100%'} gap={'4px'} alignItems={'center'}>
                        <Heading variant={'fieldArray'}>{item.SectionName}</Heading>
                        {missingQuestions.length > 0 && (
                          <CalendarAlert styleProps={{ size: 'xs', w: '200px' }} status='error'>
                            {missingQuestions.length} missing
                          </CalendarAlert>
                        )}
                        {missingQuestions.length == 0 && (
                          <CalendarAlert styleProps={{ size: 'xs', w: '200px' }} status='success'>
                            Completed
                          </CalendarAlert>
                        )}
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>
                  </Heading>
                  <AccordionPanel>
                    <Flex flexDir={'column'} gap={'12px'}>
                      {item.Questions.map((subItem, subKey) => {
                        return (
                          <CustomerChecklistRowDisplay
                            headerWidth={'100%'}
                            Render={
                              <Box>
                                {subItem.Complete ? (
                                  <Icon as={FontAwesomeIcon} color={'green'} icon={faCheck} />
                                ) : (
                                  <Icon as={FontAwesomeIcon} color={'red'} icon={faX} />
                                )}
                              </Box>
                            }
                            key={subKey}
                            Header={subItem.Question}
                          />
                        );
                      })}
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Box>
      </CardPageContainer>
      <CardPageContainer header={'Images'}>
        <Box>
          <Flex flexDir={'row'} gap={'24px'} flexWrap={'wrap'}>
            {files.data.map((file, key) => (
              <Box key={key}>
                <ImageThumbnail
                  contentType={file.ContentType}
                  content={file.FileContent}
                  fileHandle={file.FileHandle}
                />
              </Box>
            ))}
          </Flex>
          <Box fontSize={'xs'} fontStyle={'italic'}>
            Tap/Click image to view full size
          </Box>
        </Box>
      </CardPageContainer>
    </Flex>
  );
};
