import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const header = defineStyle({
  fontSize: '24px',
  paddingTop: '5px',
  paddingBottom: '5px'
});

const modalHeader = defineStyle({
  fontSize: '24px',
  paddingTop: '5px',
  paddingBottom: '0px'
});

const fieldArray = defineStyle({
  fontSize: '16px'
});

const calendarEvent = defineStyle({
  margin: '2px',
  fontSize: '12px',
  fontWeight: 'normal'
});

const calendarHeader = defineStyle({
  margin: '1px',
  padding: '0',
  fontSize: '12px',
  fontWeight: 'bold'
});

const calendarSubText = defineStyle({
  margin: '2px',
  fontSize: '10px',
  fontWeight: 'normal'
});

export const headerTheme = defineStyleConfig({
  variants: {
    header,
    modalHeader,
    fieldArray,
    calendarEvent,
    calendarSubText,
    calendarHeader
  },
  defaultProps: {
    variant: 'header'
  }
});
