import { Alert, AlertIcon, AlertProps, AlertStatus } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  status: AlertStatus;
  styleProps?: AlertProps;
  hideIcon?: boolean;
};

export const CalendarAlert = (props: Props) => {
  return (
    <Alert
      status={props.status}
      size={'sm'}
      borderRadius={'4px'}
      paddingRight={'8px'}
      paddingLeft={'4px'}
      w={'100%'}
      margin={0}
      padding={'4px'}
      fontWeight={'bold'}
      {...props.styleProps}>
      {!props.hideIcon && <AlertIcon h={'20px'} w={'20px'} marginRight={'5px'}></AlertIcon>}
      {props.children}
    </Alert>
  );
};
