//Refactoring this to pass params

import { ChecklistSectionType } from '../features/cleaningAdmin/checklists/types';
import { CleanerType } from '../enums/cleanerTyper';

const queryStringArray = (name: string, array: any[]) => {
  if (!array || array.length === 0) {
    return ``;
  }
  let toReturn = '';
  array.forEach((val) => {
    toReturn += `${name}=${val}&`;
  });
  return toReturn;
};

const getRouteForCleanerType = (type: CleanerType): string => {
  switch (type) {
    case CleanerType.Cleaner:
      return 'cleaning';
    case CleanerType.Inspection:
      return 'inspection';
    case CleanerType.Helper:
      return 'helper';
  }
};
//PascalCased = not refactored, camelCased = refactored
export const apiRoutes = {
  crm: {
    Clients: '/clients',
    ClientProperties: '/ClientProperties',
    CleaningInventoryTemplate: '/CleaningInventoryTemplate',
    ClientPropertySearch: '/ClientProperties/Search',
    ClientPropertyChecklists: '/ClientProperties/Checklists'
  },
  training: {
    TrainingVideos: '/TrainingVideo',
    Move: (trainingVideoId: number, delta: number) => `/TrainingVideo/Move/${trainingVideoId}/${delta}`
  },

  selectList: {
    enum: (typeName: string, includeZero: boolean = false) => `/SelectList/Enum/${typeName}?includeZero=${includeZero}`,
    cleanersForServiceArea: (serviceArea: string) => `/SelectList/Cleaners/${serviceArea}`,
    helpersForServiceArea: (serviceArea: string) => `/SelectList/Helpers/${serviceArea}`,
    checklistsForProperty: (propertyId: number) => `/SelectList/Checklists/${propertyId}`,
    assignToForCleaning: (role: string, cleaningId: number) => `/SelectList/cleaning/assignto/${role}/${cleaningId}`,
    clientPropertyGroupings: '/SelectList/ClientPropertyGroupings',
    requiredItems: '/SelectList/RequiredItems',
    cleaningPackages: '/SelectList/CleaningPackages',
    checklists: '/SelectList/Checklists',
    cleaningTemplateTypes: '/SelectList/CleaningTemplateTypes',
    clients: '/SelectList/ClientList',
    clientProperties: (clientId: number) => '/SelectList/PropertiesForClient?clientId=' + clientId,
    servicedCities: '/SelectList/ServicedCities',
    addons: '/SelectList/Addons',
    usersInRoles: (roles: string[]) => `SelectList/UsersInRoles?${queryStringArray('roleNames', roles)}`
  },
  Util: {
    UsersInRole: '/UsersInRole'
  },
  Cleaning: {
    CleaningPackages: '/CleaningPackage',
    InventoryTemplates: '/CleaningInventoryTemplate',
    Checklists: '/Checklist',
    CreateCleaning: (date: string, clientPropertyId: number) => `/cleaning/${clientPropertyId}?date=${date}`,
    Cleaning: '/Cleaning',
    Finish: (id: number, type: CleanerType) => `/cleaning/finish/${getRouteForCleanerType(type)}/${id}`,
    GetForType: (id: number, type: ChecklistSectionType) => `/Cleaning/${id}/${type}`,
    StartCleaning: (id: number, type: CleanerType) => `/cleaning/start/${getRouteForCleanerType(type)}/${id}`,
    ChecklistQuestions: {
      SaveQuestionResponse: (checklistSubmissionId: number) => `/checklist/questions/${checklistSubmissionId}`
    }
  },
  customerChecklist: {
    Get: (id: string) => `/CustomerChecklist/${id}`
  },
  Scheduling: {
    Base: '/Schedule',
    Details: '/Schedule/Details',
    GetCleanersSchedules: (startDate: string, endDate: string, userIds: string[]) =>
      `/schedule/cleaners/?userIds=${userIds.join(',')}&startDate=${startDate}&endDate=${endDate}`,
    GetHelpersSchedules: (startDate: string, endDate: string, userIds: string[]) =>
      `/schedule/helpers/?userIds=${userIds.join(',')}&startDate=${startDate}&endDate=${endDate}`,
    GetInspectionSchedule: (startDate: string, endDate: string) =>
      `/schedule/inspection/?startDate=${startDate}&endDate=${endDate}`
  }
};
