import { ReactNode } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

type Props = {
  Header?: string;
  Text?: string;
  Render?: ReactNode;
  headerWidth?: string;
};

export const CustomerChecklistRowDisplay = (props: Props) => {
  return (
    <Box paddingBottom={'4px'} borderBottom={'1px solid #b2b2b2'} textColor={'#5d5d5d'}>
      <Flex>
        {props.Header && <Text w={props.headerWidth ?? '150px'}>{props.Header}</Text>}
        {props.Text && <Text>{props.Text}</Text>}
        {props.Render && <Text>{props.Render}</Text>}
      </Flex>
    </Box>
  );
};
