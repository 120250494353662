import { Avatar, Box, Button, Flex, Menu, MenuButton } from '@chakra-ui/react';
import { useAuth } from '@cksoftware/react-base';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavMenuItem } from '../../components/MenuNav/NavMenuItem';
import { NavMenuSubItemList } from '../../components/MenuNav/NavMenuSubItemList';
import { NavItem } from '../../components/MenuNav/types/NavItem';
import { AppRoles } from '../../constants/appRoles';
import { ApplicationRoutes } from '../../constants/appRoutes';
import { MopspotUser } from '../../globalModels/user/mopspotUser';
import {
  AdminNavItems,
  CleaningAdminItems,
  CrmNavItems,
  SchedulingItems,
  TrainingItems,
  UnauthenticatedNavItems,
  UserNavItems
} from './NavItems';

export const NavMenus = () => {
  const auth = useAuth<MopspotUser>();
  const nav = useNavigate();

  const [navItems, setNavItems] = useState<NavItem[]>([]);

  useEffect(() => {
    if (auth.user == null) {
      setNavItems([UnauthenticatedNavItems]);
      return;
    }

    const toReturn: NavItem[] = [];
    toReturn.push(TrainingItems);
    if (auth.isInRole([AppRoles.Manager, AppRoles.Admin])) {
      toReturn.push(CleaningAdminItems);
      toReturn.push(SchedulingItems);
    }

    if (auth.isInRole([AppRoles.Admin])) {
      toReturn.push(CrmNavItems);
    }

    if (auth.isInRole([AppRoles.Admin])) {
      toReturn.push(AdminNavItems);
    }

    setNavItems(toReturn);
  }, [auth.isAuthenticated, auth.user]);

  return (
    <Flex gap={'14px'}>
      {auth.isAuthenticated && auth.isInRole([AppRoles.TeamMember]) && (
        <Box marginLeft={'auto'}>
          <Button onClick={() => nav(ApplicationRoutes.cleaning.startCleaning)}>Start Cleaning</Button>
        </Box>
      )}
      {navItems.map((value, index) => {
        return <NavMenuItem key={index} item={value} />;
      })}
      {auth.isAuthenticated && (
        <Menu size={'xs'}>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'primary'}
            cursor={'pointer'}
            size={'xs'}
            paddingTop={'10px'}>
            <Avatar
              borderWidth={'1px'}
              borderStyle={'solid'}
              borderColor={'primary.500'}
              backgroundColor={'white'}
              color={'primary.500'}
              size={{ base: 'sm', md: 'md' }}
              name={`${auth.user?.FirstName} ${auth.user?.LastName}`}
            />
          </MenuButton>
          <NavMenuSubItemList subItems={UserNavItems} />
        </Menu>
      )}
    </Flex>
  );
};
