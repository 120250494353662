import { ApplicationRoutes } from '../constants/appRoutes';

export const CleaningAdminRoutes = [
  {
    path: ApplicationRoutes.cleaningAdmin.checklists,
    async lazy() {
      const component = await import('../features/cleaningAdmin/checklists/ManageChecklists');
      return { Component: component.ManageChecklists };
    }
  },
  {
    path: ApplicationRoutes.cleaningAdmin.cleaningPackages,
    async lazy() {
      const component = await import('../features/cleaningAdmin/cleaningPackages/ManageCleaningPackages');
      return { Component: component.ManageCleaningPackages };
    }
  },
  {
    path: ApplicationRoutes.cleaningAdmin.training,
    async lazy() {
      const component = await import('../features/training/admin/TrainingVideoAdmin');
      return { Component: component.TrainingVideoAdmin };
    }
  }
];
