import { Icon, Menu, MenuButton } from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavMenuSubItemList } from './NavMenuSubItemList';
import { NavItem } from './types/NavItem';

type NavMenuItemProps = {
  item: NavItem;
};

export const NavMenuItem = ({ item }: NavMenuItemProps) => {
  const nav = useNavigate();

  const onNavClick = useCallback(
    (navItem: NavItem) => {
      if (navItem.path) {
        nav(navItem.path);
      }
    },
    [nav]
  );

  return (
    <Menu size={'sm'}>
      <MenuButton
        textColor={'black'}
        onClick={() => {
          onNavClick(item);
        }}>
        {item.text}
        {item.subItems && <Icon as={FontAwesomeIcon} icon={faChevronDown} w={'13px'} paddingLeft={'2px'} />}
      </MenuButton>
      {item.subItems && <NavMenuSubItemList subItems={item.subItems} />}
    </Menu>
  );
};
