import { Box, Flex, Image, Link } from '@chakra-ui/react';
import logo from '../../assets/mopspot-logo.png';
import { NavMenus } from './NavMenus';

export const NavBar = () => {
  return (
    <Box
      bg={'white'}
      height={'70px'}
      marginBottom={'5px'}
      borderTopWidth={{ base: '5px', lg: '10px', xl: '15px' }}
      borderTopColor={'secondary.500'}
      borderStyle={'solid'}
      boxSizing='content-box'
      boxShadow={'0px 2px 4px 0px #80808054'}>
      <Flex color={'white'} w={'100%'} h={'100%'} alignItems={'center'} gap={5} justifyContent={'space-between'}>
        <Box paddingLeft={'20px'}>
          <Link href='/'>
            <Image height={['40px']} src={logo} alt='MopSpot Hub' />
          </Link>
        </Box>

        <Box marginLeft={'auto'} marginRight={'20px'}>
          <NavMenus />
        </Box>
      </Flex>
    </Box>
  );
};
