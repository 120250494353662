import { extendTheme } from '@chakra-ui/react';
import { ChakraThemeConfig } from '@cksoftware/react-base';
import { buttonTheme } from './components/button';
import { cardTheme } from './components/card';
import { drawerTheme } from './components/drawer';
import { formTheme } from './components/form';
import { headerTheme } from './components/heading';
import { inputTheme } from './components/input';
import { modalTheme } from './components/modal';
import { numberInputTheme } from './components/numberInput';
import { selectTheme } from './components/select';
import { switchTheme } from './components/switch';
import { tabsTheme } from './components/tab';
import { tableTheme } from './components/table';
import { textTheme } from './components/text';

const themeConfig: ChakraThemeConfig = {
  paddings: {
    tableHeightBottomPadding: 150
  }
};

export const ChakraTheme = extendTheme({
  ...themeConfig,
  components: {
    Input: inputTheme,
    Button: buttonTheme,
    Select: selectTheme,
    NumberInput: numberInputTheme,
    Form: formTheme,
    Text: textTheme,
    Modal: modalTheme,
    Heading: headerTheme,
    Tabs: tabsTheme,
    Card: cardTheme,
    Table: tableTheme,
    Switch: switchTheme,
    Drawer: drawerTheme
  },

  styles: {
    global: {
      body: {
        background: '#faf9f67f'
      }
    }
  },
  colors: {
    primary: {
      15: '#e6f6f3',
      50: '#ccece7',
      100: '#b3e3db',
      200: '#99dacd',
      300: '#80d0c1',
      400: '#66c7b4',
      500: '#73C7A7', // Original color
      600: '#59a28a',
      700: '#407d6e',
      800: '#265953',
      900: '#0d3438'
    },
    secondary: {
      15: '#f7e5f2',
      50: '#efccde',
      100: '#e7b3c9',
      200: '#df99b5',
      300: '#d780a0',
      400: '#cf668b',
      500: '#c94d9d', // Original color
      600: '#a53c7e',
      700: '#802c5f',
      800: '#5c1d3f',
      900: '#38101f'
    },
    tertiary: {
      15: '#e4f3fc',
      50: '#c9e7f9',
      100: '#aedbf5',
      200: '#93cff2',
      300: '#78c3ee',
      400: '#5db7eb',
      500: '#5AB7E8', // Original color
      600: '#4891ba',
      700: '#366d8c',
      800: '#244a5e',
      900: '#12252f'
    }
  }
});
