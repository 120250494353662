import { ApplicationRoutes } from '../constants/appRoutes';

export const CrmRoutes = [
  {
    path: ApplicationRoutes.crm.clients,
    async lazy() {
      const component = await import('../features/crm/clients/ManageClients');
      return { Component: component.ManageClients };
    }
  },
  {
    path: ApplicationRoutes.crm.properties,
    async lazy() {
      const component = await import('../features/crm/properties/ManageProperties');
      return { Component: component.ManageProperties };
    }
  }
];
