import { Box, Button, Flex, Image, useDisclosure } from '@chakra-ui/react';
import { ChakraDialog, useFileDownloadMutation } from '@cksoftware/react-base';

type Props = {
  contentType: string;
  content: Uint8Array;
  fileHandle: string;
};

export const ImageThumbnail = (props: Props) => {
  const disclosure = useDisclosure();
  const fileDownload = useFileDownloadMutation();

  return (
    <Box
      onClick={disclosure.onOpen}
      cursor={'pointer'}
      width={'100px'}
      height={'150px'}
      _hover={{
        border: '1px solid #8eb3ed'
      }}>
      <Image maxW={'100px'} src={`data:${props.contentType};base64, ${props.content}`} alt={'Uploaded Image'} />
      <ChakraDialog
        disclosure={disclosure}
        footerRender={
          <Flex gap={'4px'}>
            <Button variant={'ghost'} onClick={disclosure.onClose}>
              Close
            </Button>

            <Button
              onClick={async () => {
                await fileDownload.downloadFile(props.fileHandle);
              }}>
              Download
            </Button>
          </Flex>
        }>
        <Image src={`data:${props.contentType};base64, ${props.content}`} alt={'Uploaded Image'} />
      </ChakraDialog>
    </Box>
  );
};
